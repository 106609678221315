module.exports = [{
      plugin: require('/var/www/html/codm-main_src/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/var/www/html/codm-main_src/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/var/www/html/codm-main_src/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":690},
    },{
      plugin: require('/var/www/html/codm-main_src/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/var/www/html/codm-main_src/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-41152417-57"},
    },{
      plugin: require('/var/www/html/codm-main_src/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#000000"},
    },{
      plugin: require('/var/www/html/codm-main_src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
