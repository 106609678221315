const modal_state = {
	open: true,
	type: 'scout',
	tab : 'team_deathmatch',
}

const modal = (state = modal_state, action) => {
    switch (action.type) {
		case "TOGGLE_COLLAPSE":
			return {
				...state,
				open : (action.data != state.type) ? true : !state.open,
				type: action.data
			}
		case "CHANGE_TAB":
			return { ...state, tab: action.tab };
		default:
			return state;
	}
};

export default modal;