// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-news-index-js": () => import("/var/www/html/codm-main_src/src/templates/news/index.js" /* webpackChunkName: "component---src-templates-news-index-js" */),
  "component---src-templates-redirect-js": () => import("/var/www/html/codm-main_src/src/templates/Redirect.js" /* webpackChunkName: "component---src-templates-redirect-js" */),
  "component---src-templates-guide-character-js": () => import("/var/www/html/codm-main_src/src/templates/guide/Character.js" /* webpackChunkName: "component---src-templates-guide-character-js" */),
  "component---src-templates-guide-maps-js": () => import("/var/www/html/codm-main_src/src/templates/guide/Maps.js" /* webpackChunkName: "component---src-templates-guide-maps-js" */),
  "component---src-templates-guide-feature-js": () => import("/var/www/html/codm-main_src/src/templates/guide/Feature.js" /* webpackChunkName: "component---src-templates-guide-feature-js" */),
  "component---src-templates-mode-index-js": () => import("/var/www/html/codm-main_src/src/templates/mode/index.js" /* webpackChunkName: "component---src-templates-mode-index-js" */),
  "component---src-templates-weapons-index-js": () => import("/var/www/html/codm-main_src/src/templates/weapons/index.js" /* webpackChunkName: "component---src-templates-weapons-index-js" */),
  "component---src-templates-news-post-js": () => import("/var/www/html/codm-main_src/src/templates/news/post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-pages-404-js": () => import("/var/www/html/codm-main_src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-guide-jsx": () => import("/var/www/html/codm-main_src/src/pages/guide.jsx" /* webpackChunkName: "component---src-pages-guide-jsx" */),
  "component---src-pages-index-jsx": () => import("/var/www/html/codm-main_src/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-jsx": () => import("/var/www/html/codm-main_src/src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/var/www/html/codm-main_src/.cache/data.json")

