import { combineReducers } from "redux";
import tab from './tab';
import modal from './modal';
import mode from './mode';
import feature from './feature';
import weapons from './weapons';
import character from './character';

export default combineReducers({
    modal,
    tab,
    mode,
    feature,
    weapons,
    character
});
