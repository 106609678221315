const char_state = {
    tab : false,
    all_data : false,
    current_data : false
}

const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 8);
    }
};

const character = (state = char_state, action) => {
    switch (action.type) {
        case "INIT_DATA_CHAR":
            let current_data;
            let copy_data = {...action.data}

            if (action.param) {
                current_data = copy_data.edges.filter((data) => {
                    return data.node.directusId == action.param
                })

                current_data = (current_data.length < 1) ? false : current_data[0]
            }

            return {
                ...state,
                all_data: action.data,
                current_data: (!current_data) ? copy_data.edges[0] : current_data
            }
		case "PUT_DATA_CHAR":
            return {
                ...state,
                [action.key]: action.data
            }
        case "CHANGE_TAB_CHAR":
            let current_data_char;
            let copy_data_char = {...state.all_data}

            current_data_char = copy_data_char.edges.filter((data) => {
                return data.node.directusId == action.id
            })

            current_data_char = current_data_char[0];
            scrollToTop();

            return {
                ...state,
                current_data: current_data_char
            }
		default:
			return state;
	}
};

export default character;