const tab_state = {
	tab : 'all',
	page : 1,
	page_data : []
}

const tab = (state = tab_state, action) => {
    switch (action.type) {
		case "PUT_DATA":
			return {
				...state,
				[action.key]: action.data
			}
		case "CHANGE_NEWS_TAB":
			return { ...state, tab: action.tab, page: 1 };
		default:
			return state;
	}
};

export default tab;