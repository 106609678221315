const modal_state = {
	tab : 'scorestreak',
}

const modal = (state = modal_state, action) => {
    switch (action.type) {
		case "CHANGE_TAB":
			return { ...state, tab: action.tab };
		default:
			return state;
	}
};

export default modal;