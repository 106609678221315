const weapons_state = {
	tab : false,
}

const weapons = (state = weapons_state, action) => {
    switch (action.type) {
		case "PUT_DATA_WEAPONS":
            return {
                ...state,
                [action.key]: action.data
            }
		default:
			return state;
	}
};

export default weapons;